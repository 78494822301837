import serviceIcon1 from "assets/images/service/service-icon-1.svg";
import serviceIcon2 from "assets/images/service/service-icon-2.svg";
import serviceIcon3 from "assets/images/service/service-icon-3.svg";
import serviceIcon4 from "assets/images/service/service-icon-4.svg";

export default {
  services: [
    {
      icon: serviceIcon1,
      title: "ICO Finance",
      body: "The highly the not having with lively Our up with.",
    },
    {
      icon: serviceIcon2,
      title: "Blockchain",
      body: "The highly the not having with lively Our up with.",
    },
    {
      icon: serviceIcon3,
      title: "Market News",
      body: "The highly the not having with lively Our up with.",
    },
    {
      icon: serviceIcon4,
      title: "Exchange Offer",
      body: "The highly the not having with lively Our up with.",
    },
  ],
};
